<template>
  <v-col class="noP">
    <v-container class="contenedor-listas">
      <v-col class="title_lista">
        <h4 class="title_listas_tipo">
          {{ tipo.toUpperCase() }}
        </h4>
      </v-col>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          sort-by="Nombre"
          class="elevation-0"
          :header-props="{ sortByText: 'Organizar por' }"
          no-results-text="Sin resultados"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
          }"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-text-field
                dense
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                append-outer-icon="mdi-plus"
                @click:append-outer="dialog = true"
              ></v-text-field>
              <v-dialog v-model="dialog" max-width="700px">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row
                        v-for="(e, y, index) in editedItem"
                        v-bind:key="editedItem[e]"
                      >
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItemC[y]"
                            :label="titleNew[index]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="dialog = false"
                      >Cerrar</v-btn
                    >
                    <v-btn
                      color="primary"
                      :disabled="isGuardando"
                      text
                      @click="save"
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogDelete"
                v-if="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title v-bind:style="{ 'text-align': 'center' }"
                    >Deseas borrar est@ {{ tipo }}?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="cancelDelete()"
                      >Cerrar</v-btn
                    >
                    <v-btn color="primary" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-progress-circular
              class="contenedor-tools__progress"
              :width="3"
              :size="20"
              color="primary"
              indeterminate
            >
            </v-progress-circular>
          </template>
        </v-data-table>
      </v-col>
    </v-container>
  </v-col>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "ListData",
  // Ejemplo: tipo (cead), url (endpoint con el /), global (varibale global)
  props: [
    "tipo", // para el texto
    "headers", // los headers de cada tabla
    "items", // los globals
    "url",
    "global", // por si se presentan cambios
    "editedItem", // item para editar
    "defaultItem", // items para borrar
    "forBack", // para las request que se envian de back
    "titleNew", // los labels para la creacion del nuevo item
  ],

  data() {
    return {
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      text: null,
      backupItem: null,
      editedItemC: null,
      isGuardando: false,
    };
  },

  created() {
    this.backupItem = this.editedItem;
    this.editedItemC = this.editedItem;
  },

  methods: {
    save() {
      this.isGuardando = true;
      // console.log(this.editedItemC);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          var toStore = null;
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          var k = this.forBack;
          for (let i = 0; i < k.length; i++) {
            fd.append(
              k[i],
              this.editedItemC[k[i]] == null ? "" : this.editedItemC[k[i]]
            );
          }
          toStore = this.editedItemC;
          axios.post(host + this.url, fd).then((result) => {
            let data = result.data;
            this.isGuardando = false;
            if (data.text == "OK") {
              if (this.global == "tipoG" || this.global == "estadoG") {
                this.$store.commit("add", {
                  name: this.global,
                  data: { tipo: this.editedItemC.nombre },
                });
              } else {
                this.$store.commit("add", { name: this.global, data: toStore });
              }
            } else {
              this.$alert("No se puedo agregar " + this.tipo);
            }
            this.dialog = false;
            this.editedItemC = this.defaultItem;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
          this.dialog = false;
        });
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      console.log("editedIndex");
      console.log(this.editedIndex);
      this.editedItemC = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.isGuardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          var OK = false;
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "eliminar");
          if (
            this.global == "listaNegra" ||
            this.global == "listaNegraTrabajos"
          ) {
            var data = this.$store.state[this.global][this.editedIndex];
            if (data["_id"]) {
              OK = true;
              fd.append("id", data["_id"]);
            }
          } else if (this.global == "tipoG" || this.global == "estadoG") {
            OK = true;

            var data1 = this.$store.state[this.global][this.editedIndex];
            fd.append("nombre", data1["tipo"]);
          } else {
            OK = true;
            var k = this.forBack;
            for (let i = 0; i < k.length; i++) {
              fd.append(
                k[i],
                this.editedItemC[k[i]] == null ? "" : this.editedItemC[k[i]]
              );
            }
          }
          if (OK) {
            axios.post(host + this.url, fd).then((result) => {
              let data = result.data;
              this.isGuardando = false;
              if (data.text == "OK") {
                this.$store.commit("reduce", {
                  name: this.global,
                  edited: this.editedIndex,
                });
              } else {
                this.$alert("No se puede eliminar el " + this.tipo);
              }
              this.editedIndex = -1;
            });
            this.dialogDelete = false;
            this.editedItemC = this.defaultItem;
          } else {
            this.$alert(
              "Es necesario recargar esta pagina para realizar esta operación"
            );
            this.dialogDelete = false;
            this.editedItemC = this.defaultItem;
          }
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
          this.dialogDelete = false;
        });
    },

    cancelDelete() {
      this.dialogDelete = false;
      this.editedItemC = this.backupItem;
    },
  },
};
</script>

<style scoped>
.contenedor-listas {
  margin: 0;
  padding: 0;
  border: 1px #8884 solid;
  min-width: 100%;
}
.title_lista {
  text-align: center;
  margin: 0 !important;
  padding-bottom: 0 !important;
}
.title_listas_tipo {
  color: #012355;
}
.noP {
  padding: 5px 5px !important;
}
</style>
